<template>
  <div class="readme-article">
    <h1 id="线索">一、线索</h1>
    <h2 id="线索管理">1.线索管理</h2>
    <p>1.1查看客户线索列表，管理员权限可查看所有线索，普通用户权限只可查看自己线索，支持多条件查询，可查看线索详情，编辑等操作。</p>
    <p><img src="@/assets/img/call/1-1.png" /></p>
    <p>1.2线索详情可查看客户完整信息，管理员可对其锁定（锁定后不会被回收到线索公海中）、静默（静默后不能回访且负责的业务专员也会清空）、转接待（可以讲线索转给其他业务专员负责）、放入公海（放入到线索公海中，其他业务专员可到公海领取）等操作。</p>
    <p><img src="@/assets/img/call/1-2.png" /></p>
    <p>1.3线索详情可添加回访记录，编辑意向状态，下次联系时间，内容备注等信息。</p>
    <p><img src="@/assets/img/call/1-3.png" /></p>
    <h2 id="添加线索">2.添加线索</h2>
    <p>管理员手动添加线索，姓名、手机号码，来源渠道，登记方式是必填项，完善其他信息后保存。</p>
    <p><img src="@/assets/img/call/1-4.png" /></p>
    <h2 id="组内流转">3.组内流转</h2>
    <p>业务人员组内小公海。</p>
    <!-- <p><img src="@/assets/img/call/1-5.png" /></p> -->
    <h2 id="线索公海">4.线索公海</h2>
    <p>线索公海中的线索来自批量导入、或者规则内线索超时未回访自动回收，业务专员可以在公海中领取线索。</p>
    <p><img src="@/assets/img/call/1-5.png" /></p>
    <h2 id="活跃线索">5.活跃线索</h2>
    <p>查看近期有回访的线索列表。</p>
    <p><img src="@/assets/img/call/1-6.png" /></p>
    <h2 id="二访线索">6.二访线索</h2>
    <p>查看有二次回访的线索列表。</p>
    <p><img src="@/assets/img/call/1-7.png" /></p>
    <h2 id="静默线索">7.静默线索</h2>
    <p>查看静默线索列表，设置为静默线索不能回访且负责的业务专员和接待人会清空。</p>
    <!-- <p><img src="@/assets/img/call/1-8.png" /></p> -->
    <h2 id="导入数据">8.导入数据</h2>
    <p>Excel文件批量导入客户线索，导入的线索会出现在线索公海中。</p>
    <p><img src="@/assets/img/call/1-9.png" /></p>
  </div>
</template>

<script>
export default {
  name: "call1-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>